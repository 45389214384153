import { memo, useCallback, useEffect, useState } from "react"
import { Animated, FlatList, ListRenderItemInfo, StyleSheet, View, Platform, NativeEventEmitter } from "react-native"
import { ContactItem } from "src/components/parts/ContactItem"
import { ContactListFooter, ContactListFooterMargin } from "src/components/parts/contactNetworkTab/ContactListFooter"
import { ContactQueryBuilder } from "src/components/parts/contactNetworkTab/ContactQueryBuilder"
import { DrawerStatus } from "src/components/parts/contactNetworkTab/DrawerStatus"
import { InboxIcon } from "src/components/parts/icons/drawer/InboxIcon"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { Screens } from "src/constants/Screens"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { DrawerScreenOptions } from "src/navigation/ContactNetworkDrawer"
import { useInboxData } from "src/recoil/hooks/screens/useInboxData"
import { ContactNetworkDrawerProps } from "src/types.d"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { ContactListEmpty } from "src/components/parts/contactNetworkTab/ContactListEmpty"
import { ContactFilterType } from "src/types/contact/ContactFilter"
import { DrawerLabel } from "src/components/parts/contactNetworkTab/DrawerLabel"
import { DrawerIconContainer } from "src/components/parts/contactNetworkTab/DrawerIconContainer"
import { RootTabs } from "src/constants/RootTabs"
import { useContactNetworkDrawerNavEffect } from "src/recoil/hooks/contact/useContactNetworkDrawerNavEffect"
import { Analytics } from "src/tags/analytics/Analytics"
import { BannerAd } from "src/tags/ads/BannerAd"
import { Buckets } from "src/aws/customAPI"
import { requestDataSelector } from "src/recoil/selectors/auth/requestDataSelector"
import { useRecoilValue } from "recoil"
import { getUnreadThreadCount } from "src/apis/contact/getUnreadThreadCount"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { useRecoilState } from "recoil"
import { MainLayout } from "src/layouts/MainLayout"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { permissionSelector } from "src/recoil/selectors/account/permissionSelector"
import { useIsFocused } from "@react-navigation/native"
export type InboxScreenParams = {
  organizationId?: string
}

export const InboxScreen = memo<ContactNetworkDrawerProps<typeof ContactNetworkDrawers.InboxScreen>>(
  ({ navigation, route }) => {
    const [unreadThreadCountBuckets, setUnreadThreadCountBuckets] = useState<Buckets[]>([])
    const request = useRecoilValue(requestDataSelector)
    const { value: accountPermission } = useAsyncSelector(permissionSelector)
    const checkCreateContact = accountPermission?.mail.make
    const {
      searchText,
      setSearchText,
      filters,
      setFilters,
      contacts,
      isLoadingContacts,
      refreshContacts,
      organizationId,
      myMemberId,
      unreadPerContact,
      refreshContactDetailData,
      execTrashContact,
      execUpdateFavoriteContact,
      resetCreateNewContactScreenData,
      setNextTokens,
      favoriteFlgsCache,
      organization,
    } = useInboxData()

    const [adLoadStateList] = useRecoilState(adLoadState)
    const isFocused = useIsFocused()
    useEffect(() => {
      const contactIds = contacts?.contacts.map((el) => el.id)
      if (request) getUnreadThreadCount({ ...request, contactIds }).then((data) => setUnreadThreadCountBuckets(data.buckets))
    }, [request, contacts])
    function calculateUnreadThreadCount(contactId: string, unreadThreadCountBuckets: Buckets[]) {
      const unreadThread = unreadThreadCountBuckets?.find((el) => el.key === contactId)
      return unreadThread?.doc_count ?? 0
    }

    // web版のリロードに対応するため、クエリパラメータを付与してリダイレクト
    useContactNetworkDrawerNavEffect({
      queryParamOrganizationId: route.params?.organizationId,
      screenName: ContactNetworkDrawers.InboxScreen,
      redirect: (organizationId) => {
        navigation.replace(Screens.Root, {
          screen: RootTabs.ContactNetworkDrawer,
          params: {
            screen: ContactNetworkDrawers.InboxScreen,
            params: { organizationId: organizationId },
          },
        })
      },
    })
    const gotoCreateNewContact = useCallback(async () => {
      if (organizationId != null) {
        await Analytics.goToCreateMail(organizationId)
      }
      resetCreateNewContactScreenData()
      navigation.navigate(Screens.CreateNewContactStack, { screen: CreateNewContactScreens.CreateNewContact })
    }, [resetCreateNewContactScreenData, navigation, organizationId])
    const gotoContactDetail = useCallback(
      async (contactId: string) => {
        if (organizationId == null) {
          return
        }
        await Analytics.goToMailDetailFromInbox(organizationId)
        refreshContactDetailData()
        navigation.navigate(Screens.ContactDetail, { contactId, viewMode: "Inbox", organizationId })
      },
      [navigation, organizationId, refreshContactDetailData]
    )

    const ListItem = useCallback(
      (listRenderItemInfo: ListRenderItemInfo<ContactOverviewModel>) => {
        if (myMemberId == undefined) {
          return null
        }
        if (!listRenderItemInfo.item.teamMemberContactId.includes(myMemberId)) {
          return null
        }
        return (
          <View style={styles.contentWrapper}>
            <ContactItem
              contactId={listRenderItemInfo.item.id}
              viewType="Inbox"
              title={listRenderItemInfo.item.title}
              contactType={listRenderItemInfo.item.contactType}
              sentDate={listRenderItemInfo.item.sentDate}
              timeLimitDate={listRenderItemInfo.item.deadlineDate}
              isRead={listRenderItemInfo.item.isRead}
              isAnswered={listRenderItemInfo.item.isAnswered}
              senderId={listRenderItemInfo.item.senderId}
              senderName={listRenderItemInfo.item.senderName}
              isSenderDeleted={listRenderItemInfo.item.isSenderDeleted}
              isFavorite={listRenderItemInfo.item.isFavorite}
              hasAttachment={listRenderItemInfo.item.isAttachmentExists}
              isEdited={listRenderItemInfo.item.isUpdate}
              updatedDate={listRenderItemInfo.item.updatedDate}
              onIsFavoriteChange={() =>
                execUpdateFavoriteContact({
                  contactId: listRenderItemInfo.item.id,
                  isFavorite: !(favoriteFlgsCache?.[listRenderItemInfo.item.id] ?? listRenderItemInfo.item.isFavorite),
                })
              }
              favoriteFlgsCache={favoriteFlgsCache}
              onContactPress={() => gotoContactDetail(listRenderItemInfo.item.id)}
              onTrashPress={() => execTrashContact(listRenderItemInfo.item.id)}
              noBorder
              unreadThreadCount={calculateUnreadThreadCount(listRenderItemInfo.item.id, unreadPerContact ?? [])}
              imageUrl={listRenderItemInfo.item.imgUrl}
            />
            {Platform.OS !== "web" &&
            (!organization?.paidFunctionEnabled || organization?.showAdsFlg) &&
            listRenderItemInfo.index === 1 ? (
              <View style={!isLoadDoneAd("inbox_upper", adLoadStateList, 1) && { height: 0 }}>{BannerAd.InboxUpper_1}</View>
            ) : null}

            {Platform.OS !== "web" &&
            (!organization?.paidFunctionEnabled || organization?.showAdsFlg) &&
            listRenderItemInfo.index === 3 ? (
              <View style={!isLoadDoneAd("inbox_bottom", adLoadStateList, 1) && { height: 0 }}>{BannerAd.InboxBottom_1}</View>
            ) : null}
          </View>
        )
      },
      [
        myMemberId,
        favoriteFlgsCache,
        unreadPerContact,
        adLoadStateList,
        execUpdateFavoriteContact,
        gotoContactDetail,
        execTrashContact,
        organization,
      ]
    )
    const getMoreContacts = useCallback(() => {
      setNextTokens((prev) => (contacts?.nextToken != null ? [...prev, contacts.nextToken] : prev))
    }, [contacts, setNextTokens])

    const renderCreateContactButton = () => {
      if (checkCreateContact?.accessAll || checkCreateContact?.groupIds?.length != 0) {
        return <ContactListFooter onPress={gotoCreateNewContact} />
      } else {
        return <></>
      }
    }
    const loadTopAd =
      Platform.OS !== "web" &&
      (!organization?.paidFunctionEnabled || organization?.showAdsFlg) &&
      isLoadDoneAd("inbox_top", adLoadStateList)
    const [showAnimated, setShowAnimated] = useState(true)
    const CONTAINER_HEIGHT = loadTopAd ? 50 : 1
    const scrollY = new Animated.Value(0)
    const diffClampScrollY = Animated.diffClamp(scrollY, 0, 60)
    const headerY = diffClampScrollY.interpolate({
      inputRange: [0, CONTAINER_HEIGHT],
      outputRange: [0, -CONTAINER_HEIGHT],
    })

    const handleScroll = (event: any) => {
      setShowAnimated(event.nativeEvent.contentOffset.y >= 0)
    }

    const marginTop = () => {
      if (loadTopAd && isLoadingContacts) {
        return 50
      } else if (showAnimated || !loadTopAd) {
        return 0
      }
    }
    return (
      <MainLayout>
        <View style={[styles.container]}>
          <DrawerStatus Icon={<InboxIcon color={Colors.orange} />} label="受信トレイ" />
          <View style={{ backgroundColor: Colors.white }}>
            <ContactQueryBuilder
              filterType={ContactFilterType.Inbox}
              filters={filters}
              onFiltersChange={setFilters}
              searchText={searchText}
              onSearchTextChange={setSearchText}
            />
          </View>
          {Platform.OS === "web" ? (
            <FlatList
              data={contacts?.contacts}
              renderItem={ListItem}
              ListFooterComponent={ContactListFooterMargin}
              ListEmptyComponent={!isLoadingContacts ? <ContactListEmpty label="連絡はありません。" /> : undefined}
              refreshControl={<CustomRefreshControl refreshing={isLoadingContacts && isFocused} onRefresh={refreshContacts} />}
              onEndReached={!isLoadingContacts ? getMoreContacts : undefined}
              keyExtractor={(item) => item.id}
            />
          ) : (
            <View style={{ zIndex: -5, height: "90%" }}>
              <Animated.View
                style={[
                  styles.headerA,
                  { height: loadTopAd ? 50 : 0 },
                  loadTopAd && !isLoadingContacts ? { transform: [{ translateY: showAnimated ? headerY : 0 }] } : {},
                ]}
              >
                <View style={[!loadTopAd && { height: 0, top: -1000 }, { backgroundColor: Colors.white }]}>
                  {BannerAd.InboxTop}
                </View>
              </Animated.View>
              <Animated.FlatList
                contentInset={{ top: 0 }}
                style={{ marginTop: marginTop() }}
                scrollEventThrottle={16}
                data={contacts?.contacts}
                renderItem={ListItem}
                onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
                  useNativeDriver: true,
                  listener: (event) => handleScroll(event),
                })}
                ListFooterComponent={ContactListFooterMargin}
                ListEmptyComponent={!isLoadingContacts ? <ContactListEmpty label="連絡はありません。" /> : undefined}
                refreshControl={
                  <CustomRefreshControl refreshing={isLoadingContacts && isFocused} onRefresh={refreshContacts} />
                }
                onEndReached={!isLoadingContacts ? getMoreContacts : undefined}
                keyExtractor={(item) => item.id}
                ListHeaderComponent={<View style={{ height: loadTopAd ? 60 : 0 }}></View>}
              />
            </View>
          )}
          {renderCreateContactButton()}
        </View>
      </MainLayout>
    )
  }
)

InboxScreen.displayName = "InboxScreen"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  adPlaceholder: {
    height: 100,
  },
  containerLarge: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingLeft: 10,
    paddingRight: 10,
  },
  headerA: {
    position: "absolute",
    zIndex: 10,
    left: 0,
    right: 0,
  },
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.white,
    height: 50,
  },
  headerTitle: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginLeft: 8,
  },
  contentWrapper: {
    paddingHorizontal: 16,
    paddingTop: 10,
  },
})

export const inboxScreenOptions = (showBadge: boolean): DrawerScreenOptions => ({
  title: "受信トレイ",
  drawerIcon: (props) => (
    <DrawerIconContainer>
      <InboxIcon {...props} color={Colors.orange} />
    </DrawerIconContainer>
  ),
  drawerLabel: () => <DrawerLabel title={"受信トレイ"} showBadge={showBadge} />,
})
